import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { preventEnterSubmit } from "src/lib/form-helpers";
import { TextField } from "formik-mui";
import FormErrors from "../global/forms/FormErrors";
import { Chip, Typography, Autocomplete, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { TextField as MuiTextField } from "@mui/material";

import {
  uncompleteReasonsOptions,
  reportType,
  PeruSites,
  PeruProvinces,
} from "../../lib/constants";
import theme from "../../theme";
import FormSubmitButton from "../global/elements/FormSubmitButton";
import CheckBox from "../global/elements/CheckBox";
import AcceptTerms from "../global/elements/AcceptTerms";

const DailyReportingSchema = {
  name: Yup.string(),
  missionDate: Yup.date().required("mission date is required"),
  province: Yup.array(),
  completedSiteId: Yup.array(),
  completedSiteNumber: Yup.number(),
  notCompletedSiteNumber: Yup.number(),
  notCompletedSiteId: Yup.array(),
  notCompletedReason: Yup.array().of(
    Yup.object().shape({
      reason: Yup.string(),
      details: Yup.string(),
    })
  ),
  completedUploadedSiteNumber: Yup.number(),
  uploadedSiteId: Yup.array(),
  notCompletedUploadedSiteNumber: Yup.number(),
  notCompletedUploadSiteId: Yup.array(),
  notCompletedUploadDetails: Yup.array().of(
    Yup.object().shape({
      details: Yup.string(),
    })
  ),
  megabytesPerSite: Yup.number().min(0),
  otherComments: Yup.string(),
  reportType: Yup.string(),
};

const styles = {
  root: {
    "&>.MuiFormControl-root, &>.MuiInputBase-root, &>.MuiAutocomplete-root": {
      width: "100%",
      marginBottom: "30px",
    },
  },
  formErrorsAlert: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  termsAndConditions: {
    fontSize: 14,
    lineHeight: 1.5,
    marginLeft: -12,
    marginTop: "1rem",
    textAlign: "center",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  input: {
    backgroundColor: theme.palette.grey.white,
  },
  siteInput: {
    width: "100%",
    marginBottom: "30px",
  },
  fieldTitle: {
    backgroundColor: theme.palette.secondary.lavender,
    textAlign: "center",
    marginBottom: "20px",
    padding: "5px",
  },
  checkboxGroup: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    "& div": {
      float: "left",
      width: "100%",
    },

    "& label": {
      marginTop: "7px",
      marginBottom: "7px",
    },
  },
  heading: {
    margin: "5px 0 15px 0",
  },
  alert: {
    // marginBottom: "50px",
    color: theme.palette.status.errorDark,
  },
  marginBottom: {
    marginBottom: "50px",
  },
  title: {
    color: theme.palette.grey.white,
  },
};

const renderChips = (value, getTagProps) =>
  value.map((option, index) => (
    <Chip key={index} label={option} {...getTagProps({ index })} />
  ));

const DailyReportingForm = ({
  submitHandler = undefined,
  buttonMessage = "Submit",
  type = null,
}) => {
  const validationSchema = Yup.object().shape({
    ...DailyReportingSchema,
  });
  const notCompletedCaptureGroup = (site, values, key) => {
    return (
      <>
        {values["notCompletedSiteId"][key] == site ? (
          <Box key={site}>
            <Box sx={styles.marginBottom}>
              <Typography variant="subtitle1Med" sx={styles.heading}>
                Reasons for not completing site {site}
              </Typography>
            </Box>
            <Box sx={styles.checkboxGroup}>
              {uncompleteReasonsOptions.map((value) => {
                return (
                  <Box key={value}>
                    <CheckBox
                      type="radio"
                      value={value}
                      name={`notCompletedReason[${key}].reason`}
                      label={value}
                    />
                  </Box>
                );
              })}
            </Box>
            <Field
              type="text"
              multiline
              name={`notCompletedReason[${key}].details`}
              component={TextField}
              label="If other provide more description"
              variant="outlined"
              style={{ ...styles.input, ...styles.siteInput }}
            />
          </Box>
        ) : (
          ""
        )}
      </>
    );
  };
  const notCompletedUploadGroup = (site, values, key) => {
    return (
      <>
        {values["notCompletedUploadSiteId"][key] == site ? (
          <Box key={site}>
            {" "}
            <Box sx={styles.marginBottom}>
              <Typography variant="subtitle1Med" sx={styles.heading}>
                Reasons for not completing upload of site {site}
              </Typography>
            </Box>
            <Field
              type="text"
              multiline
              name={`notCompletedUploadDetails[${key}].details`}
              component={TextField}
              label="Provide more description of why upload is not completed"
              variant="outlined"
              style={{ ...styles.input, ...styles.siteInput }}
            />
          </Box>
        ) : (
          ""
        )}
      </>
    );
  };

  const formInitialValues = {
    name: "",
    missionDate: null,
    province: [],
    completedSiteId: [],
    notCompletedSiteId: [],
    notCompletedReason: [],
    uploadedSiteId: [],
    notCompletedUploadSiteId: [],
    notCompletedUploadDetails: [],
    megabytesPerSite: 0,
    otherComments: "",
    completedSiteNumber: 0,
    completedUploadedSiteNumber: 0,
    notCompletedSiteNumber: 0,
    notCompletedUploadedSiteNumber: 0,
    reportType: type,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        enableReinitialize
      >
        {({ isSubmitting, errors, values, setFieldValue, submitCount }) => {
          return (
            <Form onKeyDown={preventEnterSubmit}>
              <Box sx={styles.root}>
                {(type === reportType.capture ||
                  type === reportType.upload) && (
                  <>
                    <Field
                      type="text"
                      name="name"
                      component={TextField}
                      label="Name*"
                      variant="outlined"
                    />
                    <Box sx={styles.fieldTitle}>
                      <Typography variant="h5" sx={styles.title}>
                        Date and location
                      </Typography>
                    </Box>

                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      disableFuture
                      fullWidth
                      onChange={(value) =>
                        setFieldValue("missionDate", value, true)
                      }
                      value={values.missionDate}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label="Mission date"
                          fullWidth
                          name="missionDate"
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                    />
                    <Autocomplete
                      multiple
                      options={PeruProvinces}
                      onChange={(e, value) =>
                        setFieldValue("province", value ?? [])
                      }
                      renderTags={renderChips}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="province"
                          component={TextField}
                          label="Province"
                          variant="outlined"
                          helperText={
                            "Select the provinces in which sites were completed today"
                          }
                        />
                      )}
                    />
                    <Box sx={styles.fieldTitle}>
                      <Typography variant="h5" sx={styles.title}>
                        Sites
                      </Typography>
                    </Box>
                  </>
                )}

                {type === reportType.capture && (
                  <>
                    <Field
                      type="number"
                      name="completedSiteNumber"
                      component={TextField}
                      label="How many sites did you complete?"
                      variant="outlined"
                      style={styles.input}
                    />
                    <Autocomplete
                      multiple
                      options={PeruSites.sort((a, b) =>
                        a.localeCompare(b, undefined, { numeric: true })
                      )}
                      onChange={(e, value) =>
                        setFieldValue("completedSiteId", value ?? [])
                      }
                      renderTags={renderChips}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="completedSiteId"
                          component={TextField}
                          label="List of all sites completed"
                          variant="outlined"
                          style={styles.input}
                          helperText="Select site IDs"
                        />
                      )}
                    />
                    {values["completedSiteNumber"] !==
                      Object.values(values["completedSiteId"]).length && (
                      <Box sx={styles.marginBottom}>
                        <Typography variant="subtitle1Med" sx={styles.alert}>
                          The number of completed sites you entered does not
                          equal the number of sites you selected
                        </Typography>
                      </Box>
                    )}
                    <Field
                      type="number"
                      name="notCompletedSiteNumber"
                      component={TextField}
                      label="How many sites were you not able to complete?"
                      variant="outlined"
                      style={styles.input}
                    />
                    <Autocomplete
                      multiple
                      options={PeruSites.sort((a, b) =>
                        a.localeCompare(b, undefined, { numeric: true })
                      )}
                      onChange={(e, value) =>
                        setFieldValue("notCompletedSiteId", value ?? [])
                      }
                      renderTags={renderChips}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="notCompletedSiteId"
                          component={TextField}
                          label="List of all sites started capture but not completed"
                          variant="outlined"
                          style={styles.input}
                          helperText="Select site IDs"
                        />
                      )}
                    />
                    {values["notCompletedSiteNumber"] !==
                      Object.values(values["notCompletedSiteId"]).length && (
                      <Box sx={styles.marginBottom}>
                        <Typography variant="subtitle1Med" sx={styles.alert}>
                          The number of the uncompleted sites you entered does
                          not equal the number of uncompleted sites you inserted
                          above
                        </Typography>
                      </Box>
                    )}
                    {Object.values(values["notCompletedSiteId"]).map(
                      (element, i) => {
                        return notCompletedCaptureGroup(element, values, i);
                      }
                    )}
                  </>
                )}

                {type === reportType.upload && (
                  <>
                    <Field
                      type="number"
                      name="completedUploadedSiteNumber"
                      component={TextField}
                      label="How many sites did you complete uploading?"
                      variant="outlined"
                      style={styles.input}
                    />
                    <Autocomplete
                      multiple
                      options={PeruSites.sort((a, b) => a - b)}
                      onChange={(e, value) => {
                        setFieldValue("uploadedSiteId", value ?? []);
                      }}
                      renderTags={renderChips}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="uploadedSiteId"
                          component={TextField}
                          label="List of all sites uploaded"
                          variant="outlined"
                          style={styles.input}
                          helperText="Select site IDs"
                        />
                      )}
                    />
                    {values["completedUploadedSiteNumber"] !==
                      Object.values(values["uploadedSiteId"]).length && (
                      <Box sx={styles.marginBottom}>
                        <Typography variant="subtitle1Med" sx={styles.alert}>
                          The number of completed sites you entered does not
                          equal the number of sites you selected
                        </Typography>
                      </Box>
                    )}
                    <Field
                      type="number"
                      name="notCompletedUploadedSiteNumber"
                      component={TextField}
                      label="How many sites were you not able to complete the upload?"
                      variant="outlined"
                      style={styles.input}
                    />
                    <Autocomplete
                      multiple
                      options={PeruSites.sort((a, b) => a - b)}
                      onChange={(e, value) =>
                        setFieldValue("notCompletedUploadSiteId", value ?? [])
                      }
                      renderTags={renderChips}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="notCompletedUploadSiteId"
                          component={TextField}
                          label="List of all sites started upload but not completed"
                          variant="outlined"
                          style={styles.input}
                          helperText="Select site IDs"
                        />
                      )}
                    />
                    {values["notCompletedUploadedSiteNumber"] !==
                      Object.values(values["notCompletedUploadSiteId"])
                        .length && (
                      <Box sx={styles.marginBottom}>
                        <Typography variant="subtitle1Med" sx={styles.alert}>
                          The number of the uncompleted uploaded sites you
                          entered does not equal the number of uncompleted
                          uploaded sites you inserted above
                        </Typography>
                      </Box>
                    )}
                    {Object.values(values["notCompletedUploadSiteId"]).map(
                      (element, i) => {
                        return notCompletedUploadGroup(element, values, i);
                      }
                    )}
                    <Field
                      type="number"
                      name="megabytesPerSite"
                      component={TextField}
                      label="Amount of Megabytes per site, in average"
                      variant="outlined"
                      style={styles.input}
                    />
                  </>
                )}
                {(type === reportType.capture ||
                  type === reportType.upload) && (
                  <>
                    <Box sx={styles.fieldTitle}>
                      <Typography variant="h5" sx={styles.title}>
                        Comments
                      </Typography>
                    </Box>
                    <Field
                      type="text"
                      multiline
                      name="otherComments"
                      component={TextField}
                      label="Any other issues or comments?"
                      variant="outlined"
                    />
                    <FormErrors
                      style={styles.formErrorsAlert}
                      show={submitCount > 0}
                      errors={Object.values(errors)}
                    />
                    <FormSubmitButton
                      label={buttonMessage}
                      submittingState={isSubmitting}
                    />
                    <AcceptTerms />
                  </>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </LocalizationProvider>
  );
};

DailyReportingForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  buttonMessage: PropTypes.string,
  type: PropTypes.string,
};
// DailyReportingForm.defaultProps = {
//   buttonMessage: "Submit",
//   type: null,
// };

export default DailyReportingForm;
